import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export const notificationCategories = {
  system_notification: 0,
  booking_prepayment: 1,
  booking_created: 2,
  booking_updated: 3,
  booking_deleted: 4,
  booking_reminder: 5,
  activate_account: 6,
  password_reset: 7,
  simple_store_transaction: 8,
  mobile_app_links: 9,
  customer_notification: 10,
  newsletter_subscribe: 11,
  verification_code: 12,
  contact_tenant_from_website: 13,

  inputValues() {
    return [
      {
        text: trans("notification_category_0"),
        value: notificationCategories.system_notification,
      },
      {
        text: trans("notification_category_1"),
        value: notificationCategories.booking_prepayment,
      },
      {
        text: trans("notification_category_2"),
        value: notificationCategories.booking_created,
      },
      {
        text: trans("notification_category_3"),
        value: notificationCategories.booking_updated,
      },
      {
        text: trans("notification_category_4"),
        value: notificationCategories.booking_deleted,
      },
      {
        text: trans("notification_category_5"),
        value: notificationCategories.booking_reminder,
      },
      {
        text: trans("notification_category_6"),
        value: notificationCategories.activate_account,
      },
      {
        text: trans("notification_category_7"),
        value: notificationCategories.password_reset,
      },
      {
        text: trans("notification_category_8"),
        value: notificationCategories.simple_store_transaction,
      },
      {
        text: trans("notification_category_9"),
        value: notificationCategories.mobile_app_links,
      },
      {
        text: trans("notification_category_10"),
        value: notificationCategories.customer_notification,
      },
      {
        text: trans("notification_category_11"),
        value: notificationCategories.newsletter_subscribe,
      },
      {
        text: trans("notification_category_12"),
        value: notificationCategories.verification_code,
      },
      {
        text: trans("notification_category_13"),
        value: notificationCategories.contact_tenant_from_website,
      },
    ];
  },
};
