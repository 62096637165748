<template>
  <div v-if="!isSending" class="list mb-16">
    <v-container fluid>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("notification_activity_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
    </v-container>
    <customer-notifications-filter
      @filtersChanged="filtersChanged"
      @filtersRestored="filtersRestored"
    />
    <v-data-table
      v-model="selected"
      :footer-props="footerProps"
      :headers="headers"
      :items="items"
      :loading="isLoadingTable"
      :loading-text="$trans('loading')"
      :no-data-text="$trans('nothing_found_here')"
      :no-results-text="$trans('nothing_found_here')"
      :options.sync="tableOptions"
      :server-items-length="total"
      class="calendesk-datatable"
      mobile-breakpoint="960"
    >
      <template #[`item`]="{ item }">
        <customer-notification-row
          :item="item"
          :is-selected="
            isRowSelected({
              id: item.id,
            })
          "
          @selectOnChange="itemRowEventOnSelectChange"
          @clickRow="handleCustomerNotificationPreview"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import sharedList from "@/calendesk/mixins/sharedList";
import { mapActions } from "vuex";
import CustomerNotificationsFilter from "@/views/dashboard/pages/Notifications/CustomerNotifications/components/CustomerNotificationsFilter.vue";
import CustomerNotificationRow from "@/views/dashboard/pages/Notifications/CustomerNotifications/components/CustomerNotificationRow.vue";
import customerNotificationActions from "@/calendesk/mixins/customerNotificationActions";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "CustomerNotificationList",
  components: {
    CalendeskInformationMessage,
    CustomerNotificationRow,
    CustomerNotificationsFilter,
  },
  mixins: [sharedList, customerNotificationActions],
  data() {
    return {
      cacheId: "customer-notification-list",
      requestActionName: "fetchCustomerNotificationListList",
      headers: [
        {
          class: "header-style",
        },
        {
          text: this.$trans("customer_type"),
          value: "customer_type",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("status"),
          value: "recipients_count",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("notification_type_title"),
          value: "notification_type",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("notification_content"),
          value: "subject",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("usage"),
          value: "usage",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("created_at"),
          value: "created_at",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "actions",
          sortable: false,
          class: "header-style",
          align: "end",
        },
      ],
    };
  },
};
</script>
