<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import customerNotificationActions from "@/calendesk/mixins/customerNotificationActions";

export default {
  components: { ListButtons },
  mixins: [customerNotificationActions],
  props: {
    notification: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      return [
        {
          action: "preview",
          color: "light",
          icon: "$magnify",
          title: this.$trans("preview"),
        },
      ];
    },
    primaryActions() {
      return [
        {
          action: "preview",
          color: "light",
          icon: "$magnify",
          title: this.$trans("preview"),
        },
      ];
    },
  },
  methods: {
    fire(action) {
      const cloned = this.$helpers.cloneObject(this.notification);
      switch (action) {
        case "preview":
          this.handleCustomerNotificationPreview(cloned);
          break;
      }
    },
  },
};
</script>
