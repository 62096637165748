var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("customer_type"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.recipients && _vm.item.recipients.length === 1)?_c('div',[(
            _vm.item.customer_type ===
              _vm.$helpers.customerNotificationCustomerType.user ||
            _vm.item.customer_type ===
              _vm.$helpers.customerNotificationCustomerType.employee
          )?_c('a',{on:{"click":function($event){$event.stopPropagation();_vm.item.recipients[0].user_id || _vm.item.recipients[0].employee_id
              ? _vm.item.customer_type ===
                _vm.$helpers.customerNotificationCustomerType.user
                ? _vm.goToUser({ id: _vm.item.recipients[0].user_id })
                : _vm.goToEmployee({ id: _vm.item.recipients[0].employee_id })
              : null}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$trans("customer_type_" + _vm.item.customer_type))+" ")]),(
              _vm.item.recipients[0].user_id || _vm.item.recipients[0].employee_id
            )?[_vm._v(" ("+_vm._s(_vm.$trans("id"))+": "),(
                _vm.item.customer_type ===
                _vm.$helpers.customerNotificationCustomerType.user
              )?[_vm._v(_vm._s(_vm.item.recipients[0].user_id))]:_vm._e(),(
                _vm.item.customer_type ===
                _vm.$helpers.customerNotificationCustomerType.employee
              )?[_vm._v(_vm._s(_vm.item.recipients[0].employee_id))]:_vm._e(),_vm._v(") ")]:_vm._e()],2):_c('span',[_vm._v(_vm._s(_vm.$trans("customer_type_" + _vm.item.customer_type)))])]):_c('div',[_vm._v(" "+_vm._s(_vm.$trans("recipients_count_title"))+": "+_vm._s(_vm.item.recipients_count)+" ")])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("status"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(
          _vm.item.recipients && _vm.item.recipients.length === 1 && _vm.getLastStatus
        )?_c('div',{staticClass:"d-flex align-center"},[(_vm.getLastStatus.details)?_c('v-tooltip',{attrs:{"max-width":"400","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.getLastStatus.status === _vm.notificationStatuses.error)?_c('v-icon',{attrs:{"color":"error","left":""}},[_vm._v("$alert")]):_c('v-icon',{attrs:{"color":"blue-light-2","left":""}},[_vm._v("$info")])],1)]}}],null,false,876528616)},[_vm._v(" "+_vm._s(_vm.getLastStatus.details)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$trans("notification_status_" + _vm.getLastStatus.status))+" ")],1):_c('div',[_vm._v(" "+_vm._s(_vm.$trans("notification_status_1"))+" ")])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__20-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("notification_type_title"))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center",class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-icon',{attrs:{"color":"green","left":""}},[_vm._v(" "+_vm._s(_vm.getIconForNotificationType(_vm.item.notification_type))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$trans("notification_type_" + _vm.item.notification_type))),_c('br'),_vm._v(" "+_vm._s(_vm.$trans("notification_category_" + _vm.item.notification_category))+" ")])],1)]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__30-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("preview"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-wrap break-word-normal"},[_vm._v(" "+_vm._s(_vm._f("stripHtml")(_vm.item.preview))+" ")])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("usage"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.fullUsage || "-")+" ")])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("created_at"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.item.created_at,_vm.$helpers.hourDayMonthAndYearDate)))])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('customer-notification-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"notification":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }