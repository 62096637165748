import { mapActions } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  methods: {
    ...mapActions({
      fetchCustomerNotificationListList: "customerNotification/fetchAll",
    }),
    handleCustomerNotificationPreview(selectedNotification) {
      this.openDialog({
        type: dialogTypes.DISPLAY_CUSTOMER_NOTIFICATION,
        title: this.$trans("display_customer_notification_title"),
        size: dialogSize.LARGE,
        showCloseDialogFooter: true,
        data: {
          selectedNotification,
        },
      });
    },
  },
};
