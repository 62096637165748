<template>
  <v-container id="customer-notifications" fluid tag="section">
    <customer-notification-list />
  </v-container>
</template>

<script>
import CustomerNotificationList from "@/views/dashboard/pages/Notifications/CustomerNotifications/components/CustomerNotificationList.vue";

export default {
  name: "CustomerNotification",
  components: { CustomerNotificationList },
};
</script>
